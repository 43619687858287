// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appm-js": () => import("./../../../src/pages/appm.js" /* webpackChunkName: "component---src-pages-appm-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-copyright-js": () => import("./../../../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-eor-js": () => import("./../../../src/pages/eor.js" /* webpackChunkName: "component---src-pages-eor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-ouroffice-js": () => import("./../../../src/pages/ouroffice.js" /* webpackChunkName: "component---src-pages-ouroffice-js" */),
  "component---src-pages-privacy-statement-js": () => import("./../../../src/pages/privacy-statement.js" /* webpackChunkName: "component---src-pages-privacy-statement-js" */),
  "component---src-pages-private-js": () => import("./../../../src/pages/private.js" /* webpackChunkName: "component---src-pages-private-js" */)
}

